.login {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 16px;
  align-items: stretch;
  text-align: left;
  row-gap: 1rem;
}

.login--loading {
  display: inline-flex;
  column-gap: 1rem;
  align-items: center;
}
