.footer .button--link {
  font-size: 12px;
}

.footer--imprint-short,
.footer--imprint,
.footer--privacy {
  color: var(--color-text-secondary);
  display: flex;
  align-items: center;
  column-gap: .25rem;
  font-size: 12px;
}

@media (max-width: 400px) {
  .footer--imprint-short {
    display: none;
  }
}
