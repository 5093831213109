.communication {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
}

.communication--content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  flex-wrap: wrap;
  column-gap: .5rem;
}

.communication--content-type {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  column-gap: .5rem;
}

.communication--content-type-text {
  color: var(--text-accent-color);
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}

.communication--content-type-confirmed-icon {
  width: 20px;
  height: 20px;
  color: var(--text-accent-color);
}

.communication--delete-icon {
  width: 18px;
  height: 18px;
}

.communication--buttons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.communication--button-delete-all {
  margin-top: 1rem;
}
