.registrations-overview {
  display: flex;
  flex-direction: column;
}

.registrations-overview--privacy {
  display: flex;
  justify-content: flex-end;
  color: var(--color-text-secondary);
  font-size: 14px;
}
