.button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  cursor: pointer;
}

.button--text {
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
}

.button--default {
  border: 1px solid var(--text-primary-color);
  color: var(--text-primary-color);
  background-color: var(--button-bg-color-default);
}

.button--default:hover {
  border: 1px solid var(--button-hover-default-color);
  color: var(--button-hover-default-color);
}

.button--primary {
  background-color: var(--button-bg-color-primary);
  color: var(--button-text-color-primary);
}

.button--primary:hover {
  background-color: var(--button-hover-bg-color-primary);
  color: var(--button-text-color-primary);
}

.button--danger {
  background-color: var(--button-bg-color-danger);
  color: var(--button-text-color-danger);
  border: 1px solid var(--button-text-color-danger);
}

.button--danger:hover {
  background-color: var(--button-hover-bg-color-danger);
  color: var(--button-hover-text-color-danger);
}

.button--disabled {
  background-color: var(--button-bg-color-disabled);
  color: var(--button-text-color-disabled);
  pointer-events: none;
}

.button--link {
  color: var(--button-text-color-link);
}

.button--link:hover {
  color: var(--button-hover-default-color)
}

.button--ghost {
  color: var(--color-text-secondary);
}

.button--link.button--disabled {
  pointer-events: none;
  background-color: transparent;
  color: var(--text-primary-color);
}
