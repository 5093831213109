.form {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.form--content {
  display: flex;
  flex-direction: column;
}

.form--label {
  color: var(--text-primary-color);
  letter-spacing: normal;
  line-height: 2em;
}

.form--input {
  background-color: var(--form-input-bg-color);
  color: var(--text-primary-color);
  font-size: 16px;
  font-weight: 300;
  line-height: 1em;
  border: 1px solid var(--form-input-border-color);
  border-radius: 5px;
  padding: 12px 16px;
}

.form--input:focus-visible {
  outline: 1px solid var(--form-input-border-color);
}

.form--error {
  background-color: var(--form-error-bg-color);
}

.form--error > p {
  color: var(--form-error-text-color);
  margin: 0;
}
