.success {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 16px;
  align-items: stretch;
  height: 100%;
  text-align: left;
}

.success--wrapper {
  display: flex;
  align-content: flex-start;
}

.success--icon {
  margin-right: 1rem;
  color: var(--text-accent-color);
  text-align: center;
}

.success--icon > svg {
  height: 120px;
  width: 120px;
}

.success--unsubscribe-info {
  margin-top: 3rem;
}

.success--unsubscribe-info p {
  font-size: 0.8rem;
  
}

.success--unsubscribe-info .button--link {
  text-decoration: underline;
}


@media only screen and (max-width: 600px) {
  .success--wrapper {
    margin-right: 0;
    flex-direction: column;

  }

  .success--icon {
    margin-right: 0rem;
  }

  .success--icon > svg {
    height: 100px;
    width: 100px;
  }
  
}

.success--redirect {
  margin-top: 3rem;
  font-size: 0.9em;
  text-align: center;
}
