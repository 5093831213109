.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: help;
}

.tooltip-text {
  visibility: hidden;

  box-sizing: border-box;
  max-width: 80vw;
  width: 368px;

  font-size: 14px;
  line-height: 1.5;
  background-color: var(--bg-overlay);
  color: var(--bg-color);
  text-align: left;

  border-radius: 5px;
  padding: 12px 16px;
  position: absolute;
  z-index: 1000;
  transform: translateY(-50%) translateX(calc(-100% - 24px));
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}