.confirmation-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  height: 100%;
  margin-top: 16px;
}

.confirmation-form--form {
  display: flex;
  flex-direction: column;
}

.input {
  border-radius: .25rem;
  border: 1px solid var(--form-input-border-color);
  margin-bottom: 1rem;
  background-color: var(--form-input-bg-color);
}

.input--code-resend {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 16px;
}

.confirmation-form--error {
  background-color: var(--form-error-bg-color);
  margin: 0 0 16px;
}

.confirmation-form--error > p {
  color: var(--form-error-text-color);
  margin: 0;
}

.confirmation-form--loading {
  display: flex;
  justify-content: center;
}

.input--code-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  input {
    text-align: center;
    color: var(--text-primary-color);
    padding: 0;
    margin: 0.25rem;
    width: 3rem;
    height: 3rem;
    border: 1px solid var(--form-input-border-color);
    border-radius: .25rem;
    background-color: var(--form-input-bg-color);
    font-weight: 500;
    font-size: 24px;

    &:focus {
      outline-color: var(--form-input-border-color);
    }
  }
}

@media (max-width: 414px) {
  .input--code .pincode-input-container .pincode-input-text {
    width: 2.25rem !important;
    height: 2.25rem !important;
    font-size: 18px;
  }
}
