.communications-overview {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.communications-overview--buttons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.communications-overview--button-delete-all {
  margin-top: 1rem;
}

.communications-overview--delete-icon {
  width: 18px;
  height: 18px;
}
