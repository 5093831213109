.register-failed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  height: 100%;
  margin-top: 16px;
}

.register-failed_title {
  margin-top: 16px;
  padding: 0 16px;
}

.register-failed_description {
  background-color: var(--form-error-bg-color);
  margin-bottom: 16px;
}
