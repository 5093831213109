.modal {
  position: relative;
  z-index: 100;
}

.modal--panel {
  width: 100%;
  max-width: 768px;
  border-radius: .25rem;
  background-color: var(--card-bg-primary);
  padding: 1rem;
}

.modal-transition--enter {
  transition-duration: 100ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.modal-transition--enter-from {
  opacity: 0;
}

.modal-transition--enter-to {
  opacity: 1;
}

.modal-transition--leave {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 100ms;
}

.modal-transition--leave-from {
  opacity: 1;
}

.modal-transition--leave-to {
  opacity: 0;
}

.modal--panel-container {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.modal--panel-container-backdrop {
  position: fixed;
  inset: 0;
  background-color: var(--bg-overlay);
}

.modal--buttons {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: .5rem;
}
