.error {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: 16px;
  text-align: left;
}

.error--redirect {
  margin-top: 1rem;
}
